.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(0, 70, 128);
  z-index: 999;
}

.header h2 {
  margin: 0;
  color: #fff;
  line-height: 64px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container svg {
  margin-right: 10px;
}

.sign-out-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sign-out-container p {
  color: #fff;
  font-size: 16px;
  margin: 0;
}

.sign-out-btn,
.notify-cs-btn {
  font-size: 16px;
  height: 40px;
  margin-left: 30px;
  margin-right: 20px;
}

.notify-cs-btn,
.notify-cs-btn:focus {
  margin-right: 0;
  background: #f48120;
}

.notify-cs-btn:hover {
  background: #ffa45a;
}

.sign-out-btn.ant-btn,
.notify-cs-btn.ant-btn {
  border: none;
}
