.main-container {
  height: calc(100vh - 52px);
  background-color: #f0f2f5;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs,
.ant-tabs-content {
  height: 100%;
}

.ant-tabs-content {
  background-color: #fff;
}

.crew-status-table .ant-table .ant-table-tbody > tr:hover > td {
  background: unset;
}

.ant-spin-dot-item {
  background-color: white;
}

.ant-spin-text {
  color: #eee;
}
