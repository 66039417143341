.summary-item {
  width: 180px;
  height: 96px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  text-align: center;
}

.summary-item .ant-typography {
  margin: 0;
}

.summary-item div {
  font-size: 24px;
  color: #f48120;
}

.summary-item.warning {
  background-color: lightcoral;
  color: #fff;
}

.summary-item.warning * {
  color: #fff;
}

.tool-tip {
  font-size: 16px;
}