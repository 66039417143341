.total-list-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}

.total-list-container p {
  margin: 0;
}
