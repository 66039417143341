.double-reg-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
}

.double-reg-container p {
  margin: 0;
}

.double-reg-item-wrapper {
  display: flex;
  justify-content: space-between;
}

.double-reg-item {
  width: 49%;
}

.double-reg-item-detail-wrapper {
  display: flex;
}

.double-reg-item-detail-time {
  margin-right: 30px;
}
