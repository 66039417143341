.crew-status-container,
.crew-detail-container {
  width: 21.5%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-left: 1px dashed rgba(0, 0, 0, 0.1);
}

.warning {
  background-color: lightcoral;
  color: #fff;
}

.crew-detail-container {
  width: 100%;
  border: none;
}

.crew-status-container .ant-table-cell p,
.crew-detail-container .ant-table-cell p {
  margin: 0;
  font-size: 13px;
}
