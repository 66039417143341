.home-container {
  height: calc(100% - 178px);
  padding: 0 20px;
  position: relative;
}

.content-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  background-color: #f0f2f5;
}

.alert {
  background-color: lightcoral;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
}

.daily-log-trigger-btn {
  position: absolute;
  top: -10px;
  right: 20px;
}
