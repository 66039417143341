.waiting-list-container {
  width: 78.5%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}

.waiting-list-container p {
  margin: 0;
}
