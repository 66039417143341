.summary-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  flex-direction: row;
}

.summary-container {
  margin: 84px 0 20px;
  display: flex;
  align-items: center;
}

.info {
  color: coral;
  height: 83px;
  width: 100%;
  margin-top: 83px;
  padding: 10px 20px;
}
